
.member-no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  height: 75vh;
  p {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
  }
}
.list-result {
  .collapse-toggle {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      background-color: #e7e4e4;
    }
  }
  background-color: white;
  .details {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    .detail-item {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      flex: 1;
      span {
        font-size: 28px;
        font-weight: 600;
        color: black;
      }
      p {
        font-size: 20px;
        color: #838383;
      }
    }
  }
  .results-collapsible {
    width: 100%;
    .results {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #dedede;
      .result {
        border-radius: 4px;
        width: 100%;
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: #efefef;
          cursor: pointer;
        }
        .result-title {
          display: flex;
          justify-content: space-between;
        }

        .result-date {
          margin-right: 30px;
          font-weight: 600;
          font-size: 20px;
          color: #797979;
        }
        .result-mark {
          margin-left: 10px;
          margin-right: 10px;
          font-size: 18px;
          span {
            font-weight: 600;
          }
        }
        .result-progress {
          flex: 1;
          position: relative;
          width: 100%;
          height: 10px;
          border-radius: 4px;
          background-color: #eeeded;
          overflow: hidden;

          .progress-inner {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 30%;
            background-color: #1d73f4;
          }
        }
      }
    }
  }

  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  .list-details {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    .normal-flag {
      margin: 0;
    }
  }
}
